import React from 'react'
import App from 'App'
import SEO from 'components/SEO'
import { Box, Flex } from 'components/Layout'
import Footer from 'components/Footer'
import { Widget } from '@typeform/embed-react'

const Contact = () => {
  return (
    <App>
      <SEO
        title="Contact Vista Social"
        description="Contacting Vista Social is easy. Send us an email or reach out on social media!"
        path="/contact/"
      />
      <Flex mt="xl" px="m" alignItems="center" flexDirection="column" textAlign="center">
        <Box width="100%" minHeight="520px" mt="l">
          <Widget id="SBGQi4gJ" style={{ width: '100%', height: '480px' }} className="my-form" />
        </Box>
      </Flex>

      <Footer />
    </App>
  )
}

export default Contact
